<template>
  <ion-page :class="{ transparent: isCameraStarted }">
    <ion-content :scroll-y="false" class="content">
      <ion-fab vertical="top" horizontal="end" slot="fixed">
        <ion-fab-button
          size="small"
          class="close-button"
          router-link="/"
          router-direction="back"
        >
          <ion-icon :icon="closeIcon"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <lens-camera ref="camera" :is-loading="isLoading" @photo="searchWine" />

      <div v-if="!isLoading" class="text-overlay ion-text-center" key="camera">
        <h5 class="ion-padding">Наведите камеру на этикетку вина</h5>
      </div>

      <ion-loading css-class="lens-loading" :is-open="isLoading" message="Определяем вино..." />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonIcon,
  IonFab,
  IonFabButton,
  IonLoading,
  modalController,
  toastController,
} from "@ionic/vue";
import { warningOutline, close } from "ionicons/icons";
import axios from "axios";
import resizePhoto from "@/lib/resizePhoto";
import { logError } from "@/lib/analytics";
import config from "@/app.config";
import LensCamera from "@/components/LensCamera.vue";
import ModalWineList from "@/components/ModalWineList.vue";

export default {
  components: {
    IonPage,
    IonContent,
    IonIcon,
    IonFab,
    IonFabButton,
    IonLoading,
    LensCamera,
  },
  name: "Lens",
  data() {
    return {
      closeIcon: close,
      errorIcon: warningOutline,
      photoUrl: null,
      isLoading: false,
      isCameraStarted: false,
    };
  },
  async ionViewWillEnter() {
    console.log("will enter");
    await this.$refs.camera.startCamera();
    this.isCameraStarted = true;
  },
  ionViewWillLeave() {
    console.log("will leave");
    this.isCameraStarted = false;
    this.$refs.camera.stopCamera();
  },
  methods: {
    async searchWine(photoUrl) {
      this.isLoading = true;
      this.photoUrl = photoUrl;

      const resizedBase64 = await resizePhoto(photoUrl);
      const searchParams = {
        page: 1,
        base64: resizedBase64,
        location: {
          Latitude: this.$store.state.location.location?.latitude,
          Longitude: this.$store.state.location.location?.longitude,
        },
      };

      try {
        const { data } = await axios.post(
          `${config.apiHost}/pairing/searchWines3.ashx`,
          JSON.stringify(searchParams),
        );

        if (data.page && data.page.length > 0) {
          if (data.page.length === 1) {
            this.openWinePage(data.page[0]);
          } else {
            this.openWineList(data.page);
          }
        } else {
          this.showErrorMessage(`Ничего не найдено`);
        }
      } catch (e) {
        this.showErrorMessage("Ошибка поиска " + e.message);
      } finally {
        this.isLoading = false;
      }
    },
    openWinePage(wine) {
      this.$store.commit("setCurrentWine", wine);
      this.$router.push({
        name: "About",
        params: {
          id: wine.id,
        },
        query: {
          latitude: this.$store.state.location.latitude,
          longitude: this.$store.state.location.longitude,
        },
      });
    },
    async openWineList(wines) {
      const modal = await modalController.create({
        component: ModalWineList,
        componentProps: {
          wines,
        },
        cssClass: "custom-modal",
        canDismiss: true,
        initialBreakpoint: 0.60,
      });
      modal.present();
    },
    async showErrorMessage(message) {
      const toast = await toastController.create({
        message,
        duration: 10000,
        position: "bottom",
        icon: this.errorIcon,
      });

      await toast.present();
      logError({ message });
    },
  },
};
</script>

<style scoped>
ion-content,
ion-header,
ion-toolbar {
  --background: #000;
}

.transparent ion-content,
.transparent ion-header,
.transparent ion-toolbar {
  --background: transparent;
}

.text-overlay {
  position: absolute;
  bottom: 120px;
  left: 0;
  padding: 0 20px;
  width: 100%;
}
.text-overlay h5 {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 250px;
  display: inline-block;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 0 50px;
}
ion-spinner {
  width: 30px;
  height: 30px;
}
ion-content::part(scroll) {
  padding-top: var(--ion-safe-area-top, 0);
  padding-bottom: var(--ion-safe-area-bottom, 0);
  padding-left: var(--ion-safe-area-left, 0);
  padding-right: var(--ion-safe-area-right, 0);
}
.close-button {
  margin-top: calc(var(--ion-safe-area-top, 0));
}
.close-button {
  --background: rgba(26, 17, 17, 0.4);
  --background-activated: rgba(0, 0, 0, 0.2);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<style>
ion-loading.lens-loading {
  --background: rgba(26, 17, 17, 0.7);
  --spinner-color: #fff;
  color: #fff;

  .loading-wrapper {
    border-radius: 20px;
  }
}
</style>
