<template>
  <div class="filter" :class="{ active: selectedFood }" ref="filter">
    <ion-button
      class="filter-button"
      fill="outline"
      mode="ios"
      @click="openPairingModal"
    >
      <span>{{ selectedFood || $t("filters.pairingLabel") }}</span>
    </ion-button>
  </div>
</template>

<script>
import { IonButton, modalController } from "@ionic/vue";
import ModalPairing from "@/components/ModalPairing.vue";

const maxLength = (string, length) => {
  if (string.length <= length) {
    return string;
  }
  return string.substring(0, length) + "...";
};

export default {
  components: {
    IonButton,
  },
  props: {
    value: {
      type: Array,
    },
  },
  methods: {
    async openPairingModal() {
      const modal = await modalController.create({
        component: ModalPairing,
        componentProps: {
          pairingOptions: this.options,
          selectedOptions: this.value,
        },
        cssClass: "custom-modal",
        presentingElement: document.getElementById("home-page"),
        canDismiss: true,
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.$emit("select", data);
      }
    },
  },
  computed: {
    options() {
      return this.$store.state.filters.foodFilters;
    },
    selectedFood() {
      if (this.options && this.value) {
        const name = this.value.map((selected) => {
          const group = this.options.find(
            (group) => group.id === selected.group_id,
          );
          if (group) {
            return group.items.find((item) => item.id === selected.item_id)
              .name;
          }
        })[0];
        return name ? maxLength(name, 30) : "";
      }
      return "";
    },
  },
};
</script>
