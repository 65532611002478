<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button router-link="/" router-direction="back">
            <ion-icon slot="icon-only" :icon="arrowBackIcon"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="centered-container">
        <h1 class="brand-logo ion-padding prevent-select" style="font-size: 40px" @click="onLogoClick">WINERADAR</h1>
        <div v-if="isNativeApp" class="muted">Версия {{ $store.state.appVersion }}</div>
        <div class="muted">
          <div>
            {{ deviceInfo.model }} {{ deviceInfo.operatingSystem }} {{ deviceInfo.osVersion }}
          </div>
          <span v-if="androidSDKVersion">Android SDK {{ androidSDKVersion }}</span>
        </div>

        <div style="margin: 50px; font-weight: 500; gap: 30px;" class="display-flex">
          <div>
            <h2>Связаться с нами</h2>
            <ion-card href="https://t.me/wineradar">
              <ion-card-content class="display-flex align-items-center">
                <img src="@/assets/telegram.svg" class="ion-padding-horizontal" />
                <div >
                  <div style="font-weight: 700; color: var(--ion-color-dark)">Telegram</div>
                  @wineradar
                </div>
              </ion-card-content>
            </ion-card>
          </div>
        </div>
        <debug-settings v-if="showDebug" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  isPlatform
} from '@ionic/vue';
import { arrowBack } from "ionicons/icons";
import { Device } from "@capacitor/device";
import DebugSettings from '@/components/DebugSettings.vue';

export default {
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    DebugSettings,
  },
  data() {
    return {
      arrowBackIcon: arrowBack,
      clickCount: 0,
      showDebug: false,
      clickTimeout: null,
      deviceInfo: () => {},
    };
  },
  async created() {
    this.deviceInfo = await Device.getInfo();
  },
  computed: {
    isNativeApp() {
      return isPlatform('capacitor');
    },
  },
  methods: {
    onLogoClick() {
      if (!this.clickTimeout) {
        console.log('set timeout');
        this.clickTimeout = setTimeout(() => {
          console.log('clear timeout')
          this.clickCount = 0;
          clearTimeout(this.clickTimeout);
          this.clickTimeout = null;
        }, 2000); // 3 seconds
      }

      this.clickCount++;

      if (this.clickCount >= 5) {
        this.showDebug = true;
      }
    }
  }
};
</script>

<style scoped>
.centered-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
h2 {
  font-weight: 700;
  font-size: 1.2em;
  text-align: center;
}
ion-card {
  width: 200px;
  margin-bottom: 10px;
  /* box-shadow: none;
  --background: transparent;
  border: 1px solid var(--ion-color-dark); */
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  user-select: none; /* Standard syntax */
}
</style>