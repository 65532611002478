<template>
  <div class="ion-page">
    <ion-header :translucent="true" class="ion-no-border" mode="ios">
      <ion-toolbar>
        <ion-title>{{ $t("filters.pairingLabel") }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="close">
            <ion-icon :icon="closeIcon"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" class="ion-padding">
      <ion-list>
        <ion-radio-group
          v-for="group in pairingOptions"
          :key="group.id"
          :value="getGroupValue(group.id)"
          @ionChange="onChange(group.id, $event)"
        >
          <ion-item v-for="item in group.items" :key="item.id">
            <ion-label class="item-label">{{ item.name }}</ion-label>
            <ion-radio slot="start" :value="item.id"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </ion-content>
    <!-- <ion-footer mode="ios">
      <ion-toolbar class="apply-toolbar ion-no-border" mode="ios">
        <ion-button @click="apply" color="primary" expand="block" mode="ios">
          Применить
        </ion-button>
      </ion-toolbar>
    </ion-footer> -->
  </div>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonRadioGroup,
  IonRadio,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonIcon,
  //IonFooter,
  modalController,
} from "@ionic/vue";
import { close } from "ionicons/icons";

const EMPTY_VALUE = { group_id: 1, item_id: 30 };

export default {
  components: {
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonRadioGroup,
    IonRadio,
    IonItem,
    IonLabel,
    IonList,
    IonTitle,
    IonIcon,
    //IonFooter,
  },
  props: {
    selectedOptions: {
      type: Array,
    },
    pairingOptions: {
      type: Array,
    },
  },
  data() {
    return {
      closeIcon: close,
      //pairingOptions: [],
      closeCallback: null,
      selected: [
        // "No meat"
        EMPTY_VALUE,
      ],
    };
  },
  mounted() {
    if (this.selectedOptions && this.selectedOptions.length) {
      this.selected = [...this.selectedOptions];
    }
  },
  methods: {
    onChange(groupId, event) {
      const itemId = parseInt(event.detail.value, 10);
      if (itemId) {
        const item = {
          group_id: groupId,
          item_id: itemId,
        };

        const index = this.selected.findIndex(
          (option) => option.group_id === groupId,
        );
        if (index !== -1) {
          this.selected.splice(index, 1);
        }
        this.selected.push(item);
      }
      setTimeout(() => {
        this.apply();
      }, 200);
    },
    getGroupValue(groupId) {
      const group = this.selected.find((option) => option.group_id === groupId);
      return group ? group.item_id : undefined;
    },
    close() {
      modalController.dismiss(null);
    },
    apply() {
      if (this.selected[0].item_id === EMPTY_VALUE.item_id) {
        modalController.dismiss([]);
      } else {
        modalController.dismiss(this.selected);
      }
    },
  },
};
</script>

<style scoped>
.item-label {
  font-size: 14px;
  white-space: normal;
}
</style>
