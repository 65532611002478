export default {
  en: {
    common: {
      loading: "Loading...",
    },
    start: {
      geolocationRequest: "Find the best wines in shops nearby",
      enterAddressButton: "Enter Address",
    },
    ageConfirm: {
      text: "Content is restricted for persons below 18 years old",
      button: "I confirm",
    },
    stories: {
      showMoreButton: "Show more",
    },
    wines: {
      noResults: "Nothing found",
      noResultsDescription: "Try to change filters values",
      showMoreButton: "Show more",
      totalFound: "Total wines found",
      regionNotAvailable: "Search is not yet available in your location",
      regionNotAvailableDescription: "Here is what we found in nearby regions:",
    },
    error: {
      generalTitle: "Something went wrong...",
      generalDescription: "We can't load the list of wines",
      tryAgainButton: "Try again",
    },
    update: {
      title: "Update the app",
      description: "New version available!",
    },
    location: {
      nearbyHeader: "Top wines nearby",
      addressLoading: "Detecting your address...",
      coordinates: "Coords",
      geolocationDisabledInSettings:
        "Geoposition is disabled in device settings.",
      geolocationGeneralError: "We can't get your location.",
      enterAddressButton: "Enter Address",
      changeAddressButton: "Change",
      currentLocation: "My current location",
      searchbarPlaceholder: "Enter Address",
      closeModal: "Close",
    },
    filters: {
      allFiltersButton: "More filters",
      resetButton: "Reset",
      applyButton: "Apply",
      noMatters: "Any",
      priceLabel: "Price",
      priceAny: "Any price",
      priceFrom: "from",
      priceTo: "to",
      wineTypeLabel: "Wine Type",
      wineStyleLabel: "Style",
      bestPrice: "Sale",
      bestPriceActive: "On sale",
      wineTypeAny: "Any",
      pairingLabel: "Pairing",
      shopChainLabel: "Shop Chain",
      blendLabel: "Grape",
      sugar: "Sugar",
      countryLabel: "Country",
      shopDistanceMeters: "you are here | {n} meter | {n} meters",
      shopDistanceKilometers: "you are here | {n} km | {n} km",
    },
    favorites: {
      title: "Favorites",
      hint: "Here you can save the wines that you are interested in",
      removedMessage: "Removed from favorites",
      addedMessage: "Added to favorites",
    },
    wine: {
      notFound: "Wine not found",
      goHome: "To HomePage",
      outOfStock: "Out of stock",
      onlinePrice: "online price",
      reviewsCount:
        "0 reviews on Vivino | {n} review on Vivino | {n} reviews on Vivino",
      orderButton: "Order",
      goToStoreButton: "Go To Store",
      ratingVivino: "Vivino rating",
      noRating: "No rating",
      showOnMapButton: "Show On Map",
      readReviewsButton: "Read Reviews On Vivino",
      searchOtherWineButton: "Show Other Wines",
      downloadAppButton: "Download App",
    },
    conclusion: {
      average: "Average",
      averageDescription: "Very basic wine, not recommended",
      good: "Good choice",
      goodDescription: "Has a good price/quality ratio",
      great: "Great choice",
      greatDescription: "Better than 85% of all wines in the world",
      excellent: "Excellent choice",
      excellentDescription: "In the 1% top wines in the world",
      lowReviews: "Not enough reviews",
      lowReviewsDescription: "We are not sure about this wine",
    },
    tag: {
      empty: "We haven't added a wine to this collection yet",
    },
    temperature: {
      fridge: "in the fridge",
      freezer: "in the freezer",
      minutes: "0 min | {n} min | {n} mins",
      hours: "0 hrs | {n} hr | {n} hrs",
    },
    rating: {
      descriptionShort:
        "Vivino - is the most popular wine app and wine rating in the world.",
      descriptionLong:
        "It shows how much the majority of users liked this wine.",
    },
    search: {
      searchbarPlaceholder: "Wine title",
      searchbarCancel: "Cancel",
      hint: "Examples",
      example1: "Champagne",
      example2: "Barolo",
      example3: "Prosecco",
      example4: "Chianti",
      example5: "Chardonnay",
      example6: "Reserve",
      example7: "Maxale",
      noResults: "Nothing found",
      noResultsDescription:
        "Please check you correclty entered the name of wine",
    },
    share: {
      title: "See wine {wine} on WINERADAR",
      text: "Wine {wine}",
    },
    network: {
      connected: "Network connected",
      notConnected: "No network connection",
    },
    rateThisApp: {
      question: "Enjoying WINERADAR app?",
      yes: "Yes!",
      no: "Not really",
    },
  },
  ru: {
    common: {
      loading: "Загрузка...",
    },
    ageConfirm: {
      text: "Вы должны быть старше 18 лет чтобы продолжить",
      button: "Мне уже есть 18",
    },
    start: {
      geolocationRequest: "Найдите лучшее вино в магазинах рядом с вами",
      enterAddressButton: "Указать адрес",
    },
    stories: {
      showMoreButton: "Показать еще",
    },
    wines: {
      noResults: "Ничего не нашли",
      noResultsDescription: "Попробуйте изменить значение одного из фильтров",
      showMoreButton: "Показать еще",
      totalFound: "Всего найдено",
      regionNotAvailable:
        "Поиск по магазинам { selectedShopName } пока недоступен в вашем городе",
      regionNotAvailableDescription: "Вот что мы нашли в ближайших регионах:",
    },
    error: {
      generalTitle: "Что-то не так...",
      generalDescription: "Мы не можем загрузить список вин",
      tryAgainButton: "Попробовать еще раз",
    },
    update: {
      title: "Обновите приложение",
      description: "Доступна новая версия!",
    },
    location: {
      nearbyHeader: "Рядом со мной",
      addressLoading: "Определяем адрес...",
      coordinates: "Координаты",
      geolocationDisabledInSettings:
        "Определение геопозиции выключено в настройках.",
      geolocationGeneralError: "Мы не можем определить вашу геопозицию",
      enterAddressButton: "Ввести адрес",
      changeAddressButton: "Изменить",
      currentLocation: "Текущее местоположение",
      searchbarPlaceholder: "Введите адрес",
      closeModal: "Закрыть",
    },
    filters: {
      allFiltersButton: "Все фильтры",
      resetButton: "Сбросить",
      applyButton: "Применить",
      noMatters: "Не важно",
      priceLabel: "Цена",
      priceAny: "Любая цена",
      priceFrom: "от",
      priceTo: "до",
      wineTypeLabel: "Тип вина",
      wineStyleLabel: "Стиль",
      bestPrice: "Скидки",
      bestPriceActive: "Со скидкой",
      wineTypeAny: "Любое",
      pairingLabel: "Еда к вину",
      shopChainLabel: "Сеть магазинов",
      blendLabel: "Виноград",
      sugar: "Сахар",
      countryLabel: "Страна",
      shopDistanceMeters: "вы здесь | {n} метр | {n} метра | {n} метров",
      shopDistanceKilometers:
        "вы здесь | {n} километр | {n} километра | {n} километров",
    },
    favorites: {
      title: "Избранное",
      hint: "Сюда вы можете сохранять вина, которые вас заинтересовали",
      removedMessage: "Удалено из Избранного",
      addedMessage: "Добавлено в Избранное",
    },
    wine: {
      notFound: "Вино не найдено",
      goHome: "На главую",
      outOfStock: "Нет в наличии",
      onlinePrice: "при заказе онлайн",
      reviewsCount:
        "отзывов на Vivino | отзыв на Vivino | отзыва на Vivino | отзывов на Vivino",
      orderButton: "Заказать",
      goToStoreButton: "В магазин",
      ratingVivino: "рейтинг Vivino",
      noRating: "Рейтинг неизвестен",
      showOnMapButton: "Показать на карте",
      readReviewsButton: "Читать отзывы на Vivino",
      searchOtherWineButton: "Искать другие вина",
      downloadAppButton: "Скачать приложение",
    },
    conclusion: {
      average: "Средне",
      averageDescription: "Очень простое вино, не рекомендуем",
      good: "Хороший выбор",
      goodDescription: "Имеет хорошее соотношение цена/качество",
      great: "Отличный выбор",
      greatDescription: "Лучше чем 85% всех вин в мире",
      excellent: "Превосходный выбор",
      excellentDescription: "Входит в 1% лучших вин мира",
      lowReviews: "Мало отзывов",
      lowReviewsDescription: "Рейтинг может быть завышен",
    },
    tag: {
      empty: "Пока в этой подборке пусто",
    },
    temperature: {
      fridge: "в холодильнике",
      freezer: "в морозилке",
      minutes: "0 минут | {n} минута | {n} минуты | {n} минут",
      hours: "0 часов | {n} час | {n} часа | {n} часов",
    },
    rating: {
      descriptionShort:
        "Vivino - это самое популярное приложение и рейтинг вина в мире.",
      descriptionLong:
        "Он показывает насколько это вино понравилось большинству пользователей.",
    },
    search: {
      searchbarPlaceholder: "Искать на WineRadar",
      searchbarCancel: "Отменить",
      hint: "Например",
      example1: "Шампанское",
      example2: "Barolo",
      example3: "Prosecco",
      example4: "Кьянти",
      example5: "Шардоне",
      example6: "Reserve",
      example7: "Maxale",
      noResults: "Ничего не нашли",
      noResultsDescription: "Проверьте, правильно ли вы ввели название вина",
    },
    share: {
      title: "Вино {wine} на WINERADAR.RU",
      text: "Вино {wine}",
    },
    network: {
      connected: "Соединение восстановлено",
      notConnected: "Нет соединения с интернетом",
    },
    rateThisApp: {
      question: "Вам нравится WINERADAR?",
      yes: "Да",
      no: "Нет",
    },
  },
};
