<template>
  <figure class="wine-image">
    <img :src="wineImageUrl" :class="{ zoomed: isZoomed }" @click="zoom" />
  </figure>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isZoomed: false,
    };
  },
  computed: {
    wineImageUrl() {
      return this.url.startsWith("//") ? `https:${this.url}` : this.url;
    },
  },
  methods: {
    zoom() {
      this.isZoomed = !this.isZoomed;
    },
  },
};
</script>

<style scoped lang="scss">
.wine-image {
  position: relative;
  z-index: 1000;
  margin: 0;
  padding-bottom: 20px;
  margin-right: 15px;

  img {
    height: 550px;
    width: auto;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
  }

  @media only screen and (max-width: 600px) {
    img {
      height: 400px;
      width: auto;
    }

    img.zoomed {
      height: 700px;
    }
  }
}
</style>
