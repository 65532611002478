<template>
  <div v-if="isStoriesEnabled" class="stories-container">
    <div ref="stories" class="stories" v-if="stories.length">
      <div
        v-for="(story, index) in latestStories"
        :key="story.title"
        class="story"
      >
        <ion-card class="story-content" :button="true">
          <img :src="storyPosterUrl(story)" @dragstart.prevent />
          <div class="overlay" @click="openStoryModal(story, index)">
            <div class="title">{{ story.title }}</div>
          </div>
        </ion-card>
      </div>
      <div ref="trigger" class="trigger"></div>
    </div>
    <div class="stories" v-if="storiesLoading">
      <div v-for="index in limit" class="story" :key="index">
        <div class="story-content"></div>
      </div>
    </div>
    <web-story-player :stories="stories" ref="modal" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { IonCard, isPlatform } from "@ionic/vue";
import WebStoryPlayer from "@/components/WebStoryPlayer";
import { logStoryViewEvent } from "@/lib/analytics";

export default {
  components: {
    IonCard,
    WebStoryPlayer,
  },
  props: {
    stories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      observer: null,
      isOpen: false,
      isLoaded: {},
      player: null,
      url: null,
      showAll: false,
      offset: 0,
      limit: 6,
    };
  },
  computed: {
    ...mapState(["storiesLoading"]),
    latestStories() {
      return this.showAll ? this.stories : this.stories.slice(0, this.limit);
    },
    isStoriesEnabled() {
      return this.$i18n.locale === "ru";
    },
  },
  watch: {
    stories() {
      this.lazyLoad();
    },
  },
  unmounted() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    lazyLoad() {
      if (!("IntersectionObserver" in window)) {
        this.showAll = true;
      }
      this.observer = new IntersectionObserver(
        ([{ isIntersecting }]) => {
          if (isIntersecting) {
            this.showAll = true;
            this.observer.disconnect();
            delete this.observer;
          }
        },
        { threshold: 0.3 },
      );
      this.$nextTick(() => {
        this.observer.observe(this.$refs.trigger);
      });
    },
    showMoreStories(event) {
      this.offset += this.limit;
      event.target.complete();
    },
    async openStoryPage(url) {
      if (isPlatform("mobileweb") || isPlatform("desktop")) {
        return (window.location.href = url);
      }
    },
    async openStoryModal(story, index) {
      this.$refs.modal.open(index);
      logStoryViewEvent(story.title);
    },
    storyPosterUrl(story) {
      return story.poster.startsWith("http")
        ? story.poster
        : `${story.url}/${story.poster}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.stories-container {
  padding-top: 15px;
}
.stories {
  --story-height: 120px;
  --story-width: 120px;
  --story-gap: 16px;
  --border-radius: 8px;
  --story-font-size: 1em;

  display: flex;
  overflow-x: scroll;
  overflow-y: auto;
  flex-wrap: nowrap;
  padding-left: 16px;
  padding-bottom: 0;
  height: calc(var(--story-height) + var(--story-gap));
  align-items: center;

  &::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}
.story {
  flex-shrink: 0;
  width: calc(var(--story-width) + var(--story-gap));

  .story-content {
    box-shadow: none;
    margin: 0;
    background-color: #eaeaea;
    border-radius: 8px;
    width: var(--story-width);
    height: var(--story-width);
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: var(--border-radius);
      z-index: 0;
    }

    .overlay {
      border-radius: var(--border-radius);
      height: 100%;
      display: flex;
      align-items: flex-end;
      background-color: rgba($color: #000000, $alpha: 0.3);
      position: absolute;
      color: #fff;
      font-weight: 600;
      font-size: var(--story-font-size);
      padding: 10px;
      z-index: 1;
      text-shadow: 0px 0px 3px #555;
    }
  }
}
.trigger {
  width: 100px;
}
</style>
