<template>
  <teleport to="body">
    <div class="lightbox" :class="{ show: isOpen }" ref="modal">
      <a href="javascript:" @click="close" class="player-close-button">
        <ion-icon :icon="closeIcon"></ion-icon>
      </a>
      <div class="player-container" ref="container">
        <iframe
          v-for="(storyUrl, index) in loadedStories"
          :key="index"
          :ref="`iframe${index}`"
          :src="storyUrl"
          :style="{ 'z-index': -(index + 1) }"
          frameborder="0"
          class="player-iframe player-iframe-loading"
        >
        </iframe>
        <ion-spinner class="spinner" color="light" />
      </div>
    </div>
  </teleport>
</template>

<script>
/* eslint-disable */
import { Messaging } from '@ampproject/viewer-messaging';
import { StatusBar, Style } from '@capacitor/status-bar';
import { useBackButton } from '@ionic/vue';
import { close } from 'ionicons/icons';
import { isPlatform, IonSpinner, IonIcon, } from '@ionic/vue';
import { markStoryAsViewed } from '@/lib/stories';

let swipeStartX = null;
let swipeStartY = null;
let swipeLength = 0;
let swipeLengthVertical = 0;
let isSwipeX = false;
let isSwipeY = false;

const initPlayer = async (story) => {
  const messaging = await Messaging.waitForHandshakeFromDocument(
    /* source window */ window,
    /* target window */ story.contentWindow,
    /* target origin */ 'https://wineradar.ru',
  );
  messaging.setDefaultHandler((name, data) => {
    if (name === 'storyContentLoaded') {
      const storyContentLoadedEvent = new CustomEvent('storyContentLoaded');
      story.dispatchEvent(storyContentLoadedEvent);
    }
    if (name === 'touchmove' || name === 'touchstart' || name === 'touchend') {
      const touch = new Touch({
        ...data.changedTouches[0],
        target: story,
      });
      const event = new TouchEvent(name, {
        changedTouches: [touch],
        touches: [touch],
      });
      story.dispatchEvent(event);

      // Create swipe events
      const swipeThreshold = 10;
      if (name === 'touchstart') {
        const x = data.touches[0].screenX;
        const y = data.touches[0].screenY;
        swipeStartX = x;
        swipeStartY = y;
      }
      if (name === 'touchmove') {
        const x = data.touches[0].screenX;
        const y = data.touches[0].screenY;
        swipeLength = swipeStartX - x;
        swipeLengthVertical = -(swipeStartY - y);

        isSwipeX = swipeLength > swipeThreshold && !isSwipeY;
        isSwipeY = swipeLengthVertical > swipeThreshold && !isSwipeX;

        if (isSwipeX) {
          const swipeXEvent = new CustomEvent('swipeX', { detail: { length: swipeLength }});
          story.dispatchEvent(swipeXEvent);
        }
        if (isSwipeY) {
          const swipeYEvent = new CustomEvent('swipeY', { detail: { length: swipeLengthVertical }});
          story.dispatchEvent(swipeYEvent);
        }
      }
      if (name === 'touchend') {
        if (isSwipeX) {
          const swipeXEndEvent = new CustomEvent('swipeXEnd', { detail: { length: swipeLength }});
          story.dispatchEvent(swipeXEndEvent);
        }
        if (isSwipeY) {
          const swipeYEndEvent = new CustomEvent('swipeYEnd', { detail: { length: swipeLengthVertical }});
          story.dispatchEvent(swipeYEndEvent);
        }
        isSwipeX = false;
        isSwipeY = false;
        swipeStartX = null;
        swipeStartY = null;
        swipeLength = 0;
        swipeLengthVertical = 0;
      }
    }

    if (name === 'selectDocument' && data.next) {
      const nextStoryEvent = new CustomEvent('nextStory');
      story.dispatchEvent(nextStoryEvent);
    }

    if (name === 'documentLoaded') {
      messaging.sendRequest('rewind', {});
      const storyLoadedEvent = new CustomEvent('storyLoaded', { detail: { title: data.title } });
      story.dispatchEvent(storyLoadedEvent);
    }
  });
};

export default {
  components: {
    IonIcon,
    IonSpinner,
  },
  props: {
    stories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loadedStories: [],
      closeIcon: close,
      isOpen: false,
    };
  },
  watch: {
    $route() {
      this.close();
    },
  },
  mounted() {
    useBackButton(10, (processNextHandler) => {
      this.isOpen = false;
      processNextHandler();
    });
    this.handleCtaClick();
  },
  computed: {
    storiesUrls() {
      const origin = encodeURIComponent(window.location.origin);
      const fragment = `#origin=${origin}&showStoryUrlInfo=0&storyPlayer=v0&cap=swipe&nativeApp=1`;
      return this.stories.map(story => {
        story.url += '?param=1';
        return story.url += fragment;
      });
    }
  },
  methods: {
    close() {
      if (isPlatform('ios')) {
        StatusBar.setStyle({
          style: Style.Light,
        });
      }
      const modal = this.$refs.modal;
      modal.style.transitionDuration = null;
      modal.style.transform = null;
      this.loadedStories = [];
      this.isOpen = false;
    },
    open(index) {
      this.loadedStories = [];
      this.loadNextStory(index);
      this.onStoryViewed(index);
      setTimeout(() => {
        this.loadNextStory();
      }, 2000);

      setTimeout(() => {
        try {
          StatusBar.setStyle({
            style: Style.Dark,
          });
        } catch (e) {
          // do nothing
        }
        this.isOpen = true;
      }, 300);
    },
    async loadNextStory(index = null) {
      let nextStoryIndex = 0;
      if (index) {
        nextStoryIndex = index;
      } else {
        if (this.loadedStories.length) {
          const lastLoadedStory = this.loadedStories[this.loadedStories.length - 1];
          const lastLoadedStoryIndex = this.storiesUrls.findIndex(url => url === lastLoadedStory);
          nextStoryIndex = lastLoadedStoryIndex + 1;
        }
      }

      const nextStory = nextStoryIndex < this.storiesUrls.length ? this.storiesUrls[nextStoryIndex] : null;
      const isLastStory = nextStoryIndex + 1 === this.storiesUrls.length;

      if (nextStory) {
        this.loadedStories.push(nextStory);
        await this.$nextTick();
        const iframe = this.$refs[`iframe${this.loadedStories.length - 1}`][0];

        initPlayer(iframe);
        this.handleStoryContentLoaded(iframe);
        this.handleNextStory(iframe);
        this.handleSwipeToClose(iframe);
        if (!isLastStory) {
          this.handleSwipeToSkip(iframe);
        }
        return nextStory;
      }
    },
    handleStoryContentLoaded(story) {
      story.addEventListener('storyContentLoaded', () => {
        story.classList.remove('player-iframe-loading');
      });
    },
    handleNextStory(story) {
      story.addEventListener('nextStory', () => {
        const width = story.offsetWidth;
        story.style.transform = `translateX(${-width}px)`;
        this.loadNextStory();
      });
    },
    handleSwipeToClose(story) {
      const modal = this.$refs.modal;
      story.addEventListener('swipeY', (e) => {
        modal.style.transitionDuration = '0s';
        modal.style.transform = `translateY(${e.detail.length}px)`;
        modal.style.backgroundColor = 'transparent';
      });
      story.addEventListener('swipeYEnd', (e) => {
        if (e.detail.length <= 100) {
          modal.style.transform = `translateY(0)`;
        } else {
          this.close();
        }
      });
      modal.addEventListener('transitionend', () => {
        modal.style.transitionDuration = null;
        modal.style.backgroundColor = null;
      });
    },
    handleSwipeToSkip(story) {
      story.addEventListener('swipeX', (e) => {
        story.style.transitionDuration = '0s';
        story.style.transform = `translateX(${-e.detail.length}px)`;
      });
      story.addEventListener('swipeXEnd', (e) => {
        const width = story.offsetWidth;
        //story.style.transitionDuration = `300ms`;
        story.style.transitionDuration = null;
        if (e.detail.length > width / 2) {
          story.style.transform = `translateX(${-width}px)`;
          this.loadNextStory();
        } else {
          story.style.transform = 'translateX(0)';
        }
      });
      story.addEventListener('transitionend', () => {
        story.style.transitionDuration = null;
      });
    },
    onStoryViewed(index) {
      markStoryAsViewed(this.stories[index].id);
    },
    handleCtaClick() {
      window.addEventListener('message', (event) => {
        if (event.data.message === 'cta-click') {
          const path = event.data.href.split('https://app.wineradar.ru').pop();
          if (path) {
            this.$router.push(path);
          }
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.player-container {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.player-iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  // background-color: #000;
  background-color: transparent;
  transition: transform 300ms ease;
  opacity: 1;
}

.lightbox {
  border-radius: 4px;
  width: 100vw; height: 100vh;
  position: absolute;
  background-color: #000000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding-top: var(--ion-safe-area-top);
  transform: translateY(100vh);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s cubic-bezier(0.075, 0.820, 0.165, 1.000);
}
.lightbox.show {
  transform: translateY(0);
}
.spinner {
  z-index: -100000;
}
.player-close-button {
  color: #ffffff;
  position: absolute;
  z-index: 100000;
  top: calc(15px + var(--ion-safe-area-top));
  left: 10px;
  font-size: 1.8em;
}
.player-iframe-loading {
}
</style>