<template>
  <ion-page>
    <ion-content
      class="ion-content search-page-content ion-text-left"
      :scroll-events="true"
      @ionScrollStart="onScroll"
    >
      <div slot="fixed" class="searchbar-container">
        <ion-searchbar
          class="searchbar"
          :placeholder="$t('search.searchbarPlaceholder')"
          debounce="500"
          ref="searchbar"
          mode="md"
          style="--box-shadow: 0"
          :cancel-button-text="$t('search.searchbarCancel')"
          show-cancel-button="always"
          :cancel-button-icon="arrowBackOutline"
          enterkeyhint="search"
          :value="query"
          @ionChange="onSearch"
          @ionCancel="onCancel"
        />
      </div>
      <ion-grid fixed class="about-grid about-grid-content">
        <div
          v-show="!isLoading && !isError && !wines.length && !isQuery"
          class="centered-container"
        >
          <div
            style="padding: 0px; margin: 0; line-height: 18px; font-weight: 700"
          >
            {{ $t("search.hint") }}
          </div>
          <div class="hint">
            <div class="badge">{{ $t("search.example1") }}</div>
            <div class="badge">{{ $t("search.example2") }}</div>
            <div class="badge">{{ $t("search.example3") }}</div>
            <div class="badge">{{ $t("search.example4") }}</div>
            <div class="badge">{{ $t("search.example5") }}</div>
            <div class="badge">{{ $t("search.example6") }}</div>
            <div class="badge">{{ $t("search.example7") }}</div>
          </div>
        </div>

        <ion-row class="wine-list-error-container" v-show="isError && !isLoading">
          <ion-col>
            <error-message @retry="search" />
          </ion-col>
        </ion-row>

        <ion-row
          class="wine-list-loader wine-list-error"
          v-if="!isLoading && !isError && wines.length === 0 && isQuery"
        >
          <ion-col class="ion-text-center">
            <h3 style="padding: 0px 20px; line-height: 22px; font-weight: 700">
              {{ $t("search.noResults") }}
            </h3>
            <small>{{ $t("search.noResultsDescription") }}</small>
          </ion-col>
        </ion-row>

        <ion-row class="wine-list" v-show="!isError && !isLoading">
          <ion-col
            size-lg="6"
            size-md="6"
            size-sm="12"
            size-xs="12"
            v-for="wine in wines"
            :key="wine.id"
          >
            <wine-card :wine="wine" />
          </ion-col>
        </ion-row>

        <ion-row class="wine-list-loader" v-show="isLoading && !isError">
          <ion-col
            size-lg="6"
            size-md="6"
            size-sm="12"
            size-xs="12"
            v-for="index in 12"
            :key="index"
          >
            <wine-card-skeleton></wine-card-skeleton>
          </ion-col>
        </ion-row>

        <ion-infinite-scroll
          @ionInfinite="fetchMoreWines($event)"
          threshold="100px"
          id="infinite-scroll"
          :disabled="total - wines.length === 0"
        >
          <ion-infinite-scroll-content :loading-text="$t('common.loading')">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonGrid,
  IonSearchbar,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  //IonIcon,
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";
import axios from "axios";
import { search } from "ionicons/icons";

import WineCard from "@/components/WineCard.vue";
import WineCardSkeleton from "@/components/WineCardSkeleton.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import config from "@/app.config";
import {
  logScreenViewEvent,
  logSearchEvent,
  logWineListViewEvent,
  logError,
} from "@/lib/analytics";

const CancelToken = axios.CancelToken;

export default {
  components: {
    ErrorMessage,
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonGrid,
    IonSearchbar,
    IonRow,
    IonCol,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    //IonIcon,
    WineCard,
    WineCardSkeleton,
  },
  data() {
    return {
      arrowBackOutline,
      query: "",
      isLoading: false,
      isError: false,
      wines: [],
      searchIcon: search,
      regionNotAvailable: false,
      page: 1,
      total: 0,
      requestInProgress: false,
    };
  },
  computed: {
    isQuery() {
      return this.query.length > 3;
    },
  },
  mounted() {
    logScreenViewEvent("search");
    setTimeout(() => {
      this.$refs.searchbar.$el.setFocus();
    }, 500);
  },
  methods: {
    setQuery(query) {
      this.query = query;
      this.search();
    },
    onSearch(event) {
      this.query = event.detail.value;
      this.search();
    },
    async onScroll() {
      const element = await this.$refs.searchbar.$el.getInputElement();
      element.blur();
    },
    onCancel() {
      return this.$router.back();
    },
    async search() {
      this.wines = [];
      this.page = 1;
      this.regionNotAvailable = false;
      if (this.isQuery) {
        this.fetchWines();
        logSearchEvent(this.query);
      }
    },
    async fetchWines() {
      this.isError = false;
      try {
        this.isLoading = true;
        if (this.requestInProgress) {
          this.requestInProgress.cancel();
        }
        this.requestInProgress = CancelToken.source();

        const searchParams = {
          page: this.page,
          searchTerm: this.query,
          location: {
            Latitude: this.$store.state.location.location.latitude,
            Longitude: this.$store.state.location.location.longitude,
          },
        };
        const { data } = await axios.post(
          `${config.apiHost}/pairing/searchWines3.ashx`,
          JSON.stringify(searchParams),
          {
            cancelToken: this.requestInProgress.token,
          },
        );
        if (data.page) {
          this.wines = this.wines.concat(data.page);
          this.total = data.total;
          logWineListViewEvent(this.wines, "Search", this.page);
        }
        this.regionNotAvailable = data.regionNotAvailable;
        this.isLoading = false;
      } catch (e) {
        if (!axios.isCancel(e)) {
          this.isError = true;
          this.isLoading = false;
          logError(e);
          throw e;
        }
      }
    },
    async fetchMoreWines(event) {
      if (this.isQuery) {
        this.page++;
        await this.fetchWines();
      }
      event.target.complete();
    },
    openWinePage(id) {
      this.$router.push({
        name: "About",
        params: { id },
        query: {
          latitude: this.$store.state.location.latitude,
          longitude: this.$store.state.location.longitude,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-page-content {
  --searchbar-height: 40px;
}
.about-grid {
  margin-top: 20px;
  --ion-grid-width: 800px;
}
.about-grid-content {
  height: 100%;
  padding-top: calc(var(--searchbar-height) + var(--ion-safe-area-top) + 18px);
  padding-left: 0;
  padding-right: 0;
}
.centered-container {
  margin-top: calc(50% - 50px);
  text-align: center;
}
.searchbar-container {
  width: 100vw;
  max-width: 800px;
  background-color: var(--ion-toolbar-background);
  padding: 10px 8px;
  padding-top: calc(var(--ion-safe-area-top) + 10px);
  display: flex;
}
.cancel-button {
  height: 2em;
  --padding-start: 0;
  --padding-end: 10px;
}
@media only screen and (min-width: 800px) {
  .searchbar-container {
    left: calc((100vw - 800px) / 2);
  }
}
.searchbar {
  height: var(--searchbar-height);

  :deep(.searchbar-input) {
    font-weight: 600;
    caret-color: var(--ion-color-primary);
  }
}
.thumbnail {
  display: block;
  height: 70px;
  width: 50px;
  object-fit: contain;
}
.hint-icon ion-icon {
  font-size: 60px;
}
.hint {
  color: var(--ion-color-dark-tint);
  margin-top: 20px;
  padding: 0 30px;
}
.badge {
  border-radius: 25px;
  display: inline-block;
  padding: 10px 20px;
  margin: 0 10px 10px 0;
  font-size: 0.8em;
  background-color: var(--ion-color-light-tint);
}
</style>
