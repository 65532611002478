<template>
  <ion-row>
    <ion-col class="ion-text-center ion-padding">
      <h5
        style="
          margin-bottom: 60px;
          padding: 0 20px;
          line-height: 22px;
          font-weight: 700;
        "
      >
        {{ $t("wines.regionNotAvailable", { selectedShopName }) }}
      </h5>
      <small>{{ $t("wines.regionNotAvailableDescription") }}</small>
    </ion-col>
  </ion-row>
</template>

<script>
import { IonCol, IonRow } from "@ionic/vue";

export default {
  components: {
    IonCol,
    IonRow,
  },
  props: {
    shopChainId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    selectedShopName() {
      return this.$store.state.filters?.shopChainFilters.find(
        (option) => option.id === this.shopChainId,
      )?.name;
    },
  },
};
</script>
