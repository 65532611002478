import axios from "axios";
import { Geolocation } from "@capacitor/geolocation";

const DADATA_KEY = "2b48f9a43fcf1f43d041e742bad563a8eade5f1c";

export function getCurrentLocation(enableHighAccuracy = false) {
  // return new Promise((resolve, reject) => {
  //   resolve({
  //     coords: {
  //       latitude: 55.755826,
  //       longitude: 37.6173,
  //     },
  //   });
  // });
  return Geolocation.getCurrentPosition({
    enableHighAccuracy,
    maximumAge: 0, // Cache is not used
    timeout: 15000,
  });
}

export function getLocationPermissions() {
  return Geolocation.checkPermissions();
}

export async function getAddressByCoords(lat, lon) {
  const url =
    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address";
  const result = await axios.post(
    url,
    { lat, lon },
    {
      headers: {
        Authorization: "Token " + DADATA_KEY,
      },
    },
  );
  return {
    results: result.data.suggestions,
  };
}

export async function searchAddress(query, locale = null, latitude = 0, longitude = 0) {
  const url =
    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
  const result = await axios.post(
    url,
    { query },
    {
      headers: {
        Authorization: "Token " + DADATA_KEY,
      },
    },
  );
  return result.data.suggestions.map((suggestion) => createLocation(suggestion));
}

// DaData already returns all required data in autocomplete
export async function getLocationByAutocomplete(autocompleteLocation, locale) {
  return {
    places: [autocompleteLocation],
  };
}

export function createLocation(suggestion, coords) {
  const location = {
    fullAddress: "",
    shortAddress: "",
    city: "",
    latitude: coords ? coords.latitude : 55.755826,
    longitude: coords ? coords.longitude : 37.6173,
    isDefault: true,
  };
  if (suggestion) {
    location.isDefault = false;
    location.fullAddress = suggestion.value;
    location.shortAddress = location.fullAddress;
    location.shortAddress = location.shortAddress.replace(
      `${suggestion.data.region_with_type}, `,
      "",
    );
    location.shortAddress = location.shortAddress.replace(
      `${suggestion.data.city_with_type}, `,
      "",
    );
    location.city = suggestion.data.city
      ? suggestion.data.city
      : suggestion.data.region_with_type;

    if (suggestion.data.geo_lat && suggestion.data.geo_lon) {
      location.latitude = parseFloat(suggestion.data.geo_lat);
      location.longitude = parseFloat(suggestion.data.geo_lon);
    }
  }
  return location;
}

export function loadMapkit() {};