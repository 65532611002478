<template>
  <ion-button mode="ios" @click="toggleFavorites">
    <ion-icon slot="icon-only" :icon="icon"></ion-icon>
  </ion-button>
</template>

<script>
import { heart, heartOutline, heartDislike } from "ionicons/icons";
import { IonButton, IonIcon, toastController } from "@ionic/vue";

export default {
  components: {
    IonButton,
    IonIcon,
  },
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    icon() {
      return this.isFavorite ? heart : heartOutline;
    },
    isFavorite() {
      return this.modelValue;
    },
  },
  methods: {
    async toggleFavorites() {
      this.$store.dispatch("toggleFavoriteWine", this.id);
      this.$emit("update:modelValue", this.isFavorite ? 0 : 1);

      const toast = await toastController.create({
        color: "dark",
        cssClass: "rate-toast ion-text-left",
        duration: 2000,
        header: "",
        message: this.isFavorite
          ? this.$t("favorites.removedMessage")
          : this.$t("favorites.addedMessage"),
        position: "bottom",
        icon: this.isFavorite ? heartDislike : heart,
      });
      return toast.present();
    },
  },
};
</script>
