<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button router-link="/" router-direction="back">
            <ion-icon slot="icon-only" :icon="arrowBackIcon"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title class="ion-text-center">{{ $t("favorites.title") }}</ion-title>
        <ion-buttons slot="end"><div style="width: 48px;"></div></ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-content">
      <!-- <ion-header  class="ion-no-border">
        <ion-grid fixed style="padding: 0" class="page-grid">
          <ion-toolbar>
            <ion-title size="large">{{ $t("favorites.title") }}</ion-title>
          </ion-toolbar>
        </ion-grid>
      </ion-header> -->


      <ion-grid fixed class="page-grid page-grid-content">
        <div
          v-show="!isLoading && !isError && !favoriteWines.length"
          class="centered-container"
        >
          <div class="hint-icon">
            <img src="@/assets/favorites-empty.svg" style="width: 200px;" />
          </div>
          <div class="hint">{{ $t("favorites.hint") }}</div>
        </div>

        <ion-row class="wine-list-error-container" v-show="isError">
          <ion-col>
            <error-message @retry="search" />
          </ion-col>
        </ion-row>

        <ion-row class="wine-list">
          <ion-col
            size-lg="6"
            size-md="6"
            size-sm="12"
            size-xs="12"
            v-for="wine in favoriteWines"
            :key="wine.id"
          >
            <wine-card :wine="wine" />
          </ion-col>
        </ion-row>
        <ion-row class="wine-list-loader" v-show="isLoading">
          <ion-col
            size-lg="6"
            size-md="6"
            size-sm="12"
            size-xs="12"
            v-for="index in 12"
            :key="index"
          >
            <wine-card-skeleton></wine-card-skeleton>
          </ion-col>
        </ion-row>

        <ion-infinite-scroll
          @ionInfinite="fetchMoreWines($event)"
          threshold="100px"
          id="infinite-scroll"
          :disabled="total - wines.length === 0"
        >
          <ion-infinite-scroll-content :loading-text="$t('common.loading')">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonButtons,
  IonButton,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonIcon,
} from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import axios from "axios";

import WineCard from "@/components/WineCard.vue";
import WineCardSkeleton from "@/components/WineCardSkeleton.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import config from "@/app.config";
import {
  logWineListViewEvent,
  logError,
  logScreenViewEvent,
} from "@/lib/analytics";

const CancelToken = axios.CancelToken;

export default {
  components: {
    ErrorMessage,
    IonPage,
    IonHeader,
    IonContent,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonButtons,
    IonButton,
    IonRow,
    IonCol,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonIcon,
    WineCard,
    WineCardSkeleton,
  },
  data() {
    return {
      arrowBackIcon: arrowBack,
      isLoading: false,
      isError: false,
      wines: [],
      page: 1,
      total: 0,
      requestInProgress: false,
    };
  },
  mounted() {
    logScreenViewEvent("favorites");
    this.search();
  },
  computed: {
    favoriteWines() {
      return this.wines.filter((wine) => wine.favorite);
    },
  },
  methods: {
    async search() {
      this.wines = [];
      this.page = 1;
      this.fetchWines();
    },
    async fetchWines() {
      this.isError = false;
      try {
        this.isLoading = true;
        if (this.requestInProgress) {
          this.requestInProgress.cancel();
        }
        this.requestInProgress = CancelToken.source();

        const searchParams = {
          page: this.page,
          favorites: 1,
          location: {
            Latitude: this.$store.state.location.location.latitude,
            Longitude: this.$store.state.location.location.longitude,
          },
        };
        const { data } = await axios.post(
          `${config.apiHost}/pairing/getwines3.ashx`,
          JSON.stringify(searchParams),
          {
            cancelToken: this.requestInProgress.token,
          },
        );
        if (data.page) {
          this.wines = this.wines.concat(data.page);
          this.total = data.total;
          logWineListViewEvent(this.wines, "Favorites", this.page);
        }
        this.isLoading = false;
      } catch (e) {
        if (!axios.isCancel(e)) {
          this.isError = true;
          this.isLoading = false;
          logError(e);
          throw e;
        }
      }
    },
    async fetchMoreWines(event) {
      this.page++;
      await this.fetchWines();
      event.target.complete();
    },
    openWinePage(id) {
      this.$router.push({
        name: "About",
        params: { id },
        query: {
          latitude: this.$store.state.location.latitude,
          longitude: this.$store.state.location.longitude,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-grid {
  --ion-grid-width: 800px;
}
.page-grid-content {
  height: 100%;
  padding-top: 10px;
  padding-left: 0;
  padding-right: 0;
}
.centered-container {
  margin-top: calc(50% - 50px);
  text-align: center;
}
.thumbnail {
  display: block;
  height: 70px;
  width: 50px;
  object-fit: contain;
}
.hint-icon ion-icon {
  font-size: 60px;
}
.hint {
  color: var(--ion-color-dark-tint);
  margin: 10px 40px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
</style>
