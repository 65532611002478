<template>
  <ion-card
    class="ion-padding"
    style="margin-top: 0"
    mode="ios"
    :button="true"
    :href="storeLink"
  >
    <div class="banner">
      <div class="banner-text">
        <b style="font-size: 15px">{{ $t("update.title") }}</b>
        <div style="padding: 3px 0 7px 0">{{ $t("update.description") }}</div>
        <ion-button color="primary" fill="solid" size="small" :href="storeLink">
          Обновить
        </ion-button>
      </div>
      <img src="@/assets/champagne.svg" class="banner-image" />
    </div>
  </ion-card>
</template>

<script>
import { IonButton, IonCard } from "@ionic/vue";
import { getAppStoreLink } from "@/lib/url";

export default {
  components: {
    IonButton,
    IonCard,
  },
  computed: {
    storeLink() {
      return getAppStoreLink();
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-text {
    padding-right: 10px;
    color: #262626;
    b {
      color: #000;
    }
  }
}
.banner-image {
  height: 70px;
}

@media only screen and (min-width: 600px) {
  ion-card {
    width: 350px;
  }
}
</style>
