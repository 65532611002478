<template>
  <ion-card class="wine-about-widget wine-shop-card" target="_blank" mode="ios">
    <ion-card-content v-if="shops.length">
      <div v-for="shop in shops" class="wine-shop-container">
        <div class="wine-shop-price-container ion-margin-bottom">
          <div class="wine-shop-price">
            <span class="actual-price"
              >{{ Math.floor(shop.price) }} {{ getCurrencyByCode(shop.currency) }}</span
            >
            <span v-if="shop.basePrice && shop.basePrice !== shop.price" class="base-price">
              {{ Math.floor(shop.basePrice) }}
              {{ getCurrencyByCode(shop.currency) }}
            </span>
          </div>
          <img :src="getLogoPath(shop)" class="wine-shop-logo" />
        </div>

        <span
          v-if="shop.hasOnlineDiscount && getOnlineDiscount(shop)"
          class="price-description ion-margin-bottom"
        >
          <ion-badge style="margin-right: 5px">-{{ getOnlineDiscount(shop) }}%</ion-badge>
          <span>{{ $t("wine.onlinePrice") }}</span>
        </span>

        <div class="wine-shop ion-margin-bottom">
          <div class="wine-shop-address">
            <span>{{ shop.chain.name }}</span>
            <div>{{ shop.address }}</div>
          </div>
        </div>

        <ion-button
          v-if="shop.url"
          :href="shop.url"
          target="_blank"
          color="primary"
          expand="block"
          fill="outline"
          :strong="true"
          mode="ios"
          @click="onGoToStoreClick(shop)"
        >
          {{
            shop.hasOnlineDiscount
              ? $t("wine.orderButton")
              : $t("wine.goToStoreButton")
          }}
        </ion-button>
      </div>

      <shops-map v-if="shops.length" :shops="shops" />
    </ion-card-content>

    <ion-card-content v-else>
      <div class="wine-shop-price-container">
        <div class="wine-shop-price">
          {{ $t("wine.outOfStock") }}
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardContent, IonButton, IonBadge } from "@ionic/vue";
import { logGoToStoreEvent } from "@/lib/analytics";
import { getCurrencyByCode } from "@/lib/currency";
import ShopsMap from "@/components/ShopsMap.vue";

export default {
  components: {
    IonCard,
    IonCardContent,
    IonButton,
    IonBadge,
    ShopsMap,
  },
  props: {
    shops: {
      type: Object,
      required: true,
    },
  },
  methods: {
    logGoToStoreEvent,
    getCurrencyByCode,
    getLogoPath(shop) {
      return shop.chain.logo ? `https://app.wineradar.ru${shop.chain.logo}` : null;
    },
    getOnlineDiscount(shop) {
      if (!shop.basePrice) {
        return 0;
      }
      const discount = shop.basePrice - thshopis.price;
      return discount > 0 ? Math.ceil((discount * 100) / shop.basePrice) : 0;
    },
    onGoToStoreClick(shop) {
      this.$emit("goToStoreClick", shop);
    },
  },
};
</script>

<style lang="scss" scoped>
.wine-shop-container {
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.wine-shop {
  display: flex;
  align-items: center;

  &-address {
    flex: 1;
    color: var(--ion-color-dark);
    font-size: 0.8em;

    span {
      font-weight: 600;
      font-size: 16px;
      display: block;
    }
  }
  &-price {
    margin-right: 10px;

    .base-price {
      font-size: 18px;
      text-decoration: line-through;
      padding-right: 10px;
    }
    .actual-price {
      font-size: 26px;
      padding-right: 10px;
    }
  }
}
.wine-shop-price-container {
  display: flex;
  justify-content: space-between;
}
.price-description {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  align-items: center;
}
.wine-shop-logo {
  display: block;
  max-height: 40px;
  max-width: 120px;
  object-fit: contain;
}
.price-box {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
</style>
