export default (url, maxWidth = 512, maxHeight = 512) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = url;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const scale = Math.min(maxWidth / image.width, maxHeight / image.height);
      const newWidth = image.width * scale;
      const newHeight = image.height * scale;

      // Set canvas dimensions
      canvas.width = newWidth;
      canvas.height = newHeight;

      // Draw and resize image on canvas
      ctx.imageSmoothingEnabled = true;
      ctx.drawImage(image, 0, 0, newWidth, newHeight);
      resolve(ctx.canvas.toDataURL("image/jpeg", 0.95));
    };
  });
};
