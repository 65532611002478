import { createRouter, createWebHistory } from "@ionic/vue-router";
//import Home from "@/views/Home.vue";
import HomeRedesign from "@/views/HomeRedesign.vue";
//import About from "@/views/About.vue";
import AboutRedesign from "@/views/AboutRedesign.vue";
import Search from "@/views/Search.vue";
import StartScreen from "@/views/StartScreen.vue";
import Favorites from "@/views/Favorites.vue";
import Tag from "@/views/Tag.vue";
import Wizard from "@/views/Wizard.vue";
import Lens from "@/views/Lens.vue";
import Settings from "@/views/Settings.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeRedesign,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  {
    path: "/wine/:id",
    name: "About",
    component: AboutRedesign,
  },
  {
    path: "/start",
    name: "StartScreen",
    component: StartScreen,
  },
  {
    path: "/favorites",
    name: "Favorites",
    component: Favorites,
  },
  {
    path: "/tag/:tag",
    name: "Tag",
    component: Tag,
  },
  {
    path: "/lenta",
    name: "Wizard",
    component: Wizard,
  },
  {
    path: "/lens",
    name: "Lens",
    component: Lens,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
