<template>
  <ion-page ref="page" id="home-page">
    <ion-header class="ion-no-border" collapse="fade">
      <div style="height: var(--ion-safe-area-top, 0)"></div>
    </ion-header>

    <ion-content class="ion-text-left home-page-content" ref="content">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <!-- <ion-header class="ion-no-border ion-padding padding-bottom-0" mode="ios">
        <ion-grid fixed>
          <ion-toolbar>
            <ion-buttons slot="start" class="buttons-collapse">
              <location-selector :is-location-blocked="isLocationBlocked" />
            </ion-buttons>
            <ion-buttons slot="end" class="buttons-collapse">
              <ion-button
                v-if="isNativeApp"
                class="search-button"
                router-link="/favorites"
              >
                <ion-icon :icon="favoritesIcon"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-grid>
      </ion-header> -->


      <ion-grid fixed style="--ion-grid-padding: 0">

        <header class="display-flex justify-content-space-between align-items-center ion-padding-horizontal" style="padding-bottom: 10px">
          <location-selector :is-location-blocked="isLocationBlocked" />
          <div class="display-flex align-items-center flex-shrink-0">
            <ion-button
              v-if="true"
              class="circle-button"
              fill="clear"
              size="large"
              router-link="/favorites"
            >
              <ion-icon :icon="favoritesIcon"></ion-icon>
            </ion-button>
            <ion-button
              class="circle-button"
              fill="clear"
              size="large"
              router-link="/settings"
            >
              <ion-icon :icon="helpIcon"></ion-icon>
            </ion-button>
          </div>
        </header>

        <filter-search @focus="openSearch" />

        <service-message />

        <rate-this-app />

        <update-banner v-if="isNativeApp && config.updateAvailable" />

        <!-- <stories :stories="stories" /> -->

        <!-- <ion-row>
          <ion-col> -->
            <!-- <filters class="ion-padding" @select="onFiltersApply" /> -->
            <filter-shop
              :value="filtersValues.shop"
              :shop-chain="filtersValues.shopChain"
              @select="setShop"
            />


            <filters class="ion-padding" style="padding-top: 0" @select="onFiltersApply" />

            <order-selector @change="setSortOrder" class="ion-padding-horizontal" />
          <!-- </ion-col>
        </ion-row> -->

        <ion-row v-if="winesLoadingError" class="wine-list-error-container">
          <ion-col>
            <error-message @retry="fetchWines" />
          </ion-col>
        </ion-row>
        <region-not-available
          v-if="winesRegionNotAvailable && wines.length"
          :shop-chain-id="filtersValues.shopChain"
        />
        <ion-row
          v-if="
            !winesLoading &&
            !winesLoadingError &&
            !locationLoading &&
            !filtersLoading &&
            wines.length === 0
          "
          class="wine-list-error-container"
        >
          <ion-col class="ion-text-center">
            <img src="@/assets/not-found.svg" alt="No results" style="width: 100px" />
            <h3 style="padding: 0 20px; line-height: 22px; font-weight: 700">
              {{ $t("wines.noResults") }}
            </h3>
            <small>{{ $t("wines.noResultsDescription") }}</small>
          </ion-col>
        </ion-row>

        <div class="wine-list">
          <ion-row>
            <ion-col
              size-lg="4"
              size-md="6"
              size-sm="12"
              size-xs="12"
              v-for="wine in wines"
              :key="wine.id"
              class="wine-card-col"
            >
              <wine-card :wine="wine" />
            </ion-col>
          </ion-row>
          <ion-row
            class="wine-list wine-list-loader"
            v-show="(winesLoading || locationLoading || filtersLoading) && !winesLoadingError"
          >
            <ion-col
              size-lg="4"
              size-md="6"
              size-sm="12"
              size-xs="12"
              v-for="index in 12"
              :key="index"
            >
              <wine-card-skeleton></wine-card-skeleton>
            </ion-col>
          </ion-row>
        </div>

        <div
          v-show="
            !winesLoading &&
            !winesLoadingError &&
            !locationLoading &&
            !filtersLoading
          "
          class="ion-text-center ion-padding"
          style="padding-top: 0"
        >
          <ion-button
            v-show="totalWines - wines.length"
            fill="outline"
            mode="ios"
            shape="round"
            @click="fetchMoreWines"
          >
            {{ $t("wines.showMoreButton") }}
          </ion-button>
          <div v-show="totalWines > 0" class="total-wines">
            <small>{{ $t("wines.totalFound") }} {{ totalWines }}</small>
          </div>
        </div>

      </ion-grid>

    </ion-content>
    <age-confirm />
  </ion-page>
</template>

<script>
/* eslint-disable */
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  isPlatform,
  useBackButton,
} from '@ionic/vue';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { StatusBar, Style } from '@capacitor/status-bar';
import { arrowUpOutline, search, heartOutline, helpCircleOutline } from 'ionicons/icons';
import { mapActions, mapState } from 'vuex';

import AgeConfirm from '@/components/AgeConfirm.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import Filters from '@/components/Filters.vue';
import FilterSearch from '@/components/FilterSearch.vue';
import LocationSelector from '@/components/LocationSelector.vue';
import FilterShop from '@/components/FilterShop.vue';
import UpdateBanner from '@/components/UpdateBanner.vue';
import Stories from '@/components/Stories.vue';
import WineCard from '@/components/WineCard.vue';
import WineCardSkeleton from '@/components/WineCardSkeleton.vue';
import RegionNotAvailable from '@/components/RegionNotAvailable.vue';
import ServiceMessage from '@/components/ServiceMessage.vue';
import RateThisApp from '@/components/RateThisApp.vue';
import LensButton from '@/components/LensButton.vue';
import OrderSelector from '@/components/OrderSelector.vue';

import { getAppStoreLink } from '@/lib/url';
import { logScreenViewEvent } from '@/lib/analytics';

export default {
  name: 'Home',
  components: {
    AgeConfirm,
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonButton,
    IonButtons,
    IonCol,
    IonRow,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    WineCard,
    WineCardSkeleton,
    Stories,
    LocationSelector,
    FilterShop,
    FilterSearch,
    Filters,
    UpdateBanner,
    ErrorMessage,
    RateThisApp,
    RegionNotAvailable,
    ServiceMessage,
    LensButton,
    OrderSelector,
  },
  provide() {
    return {
      page: this.pageElement
    }
  },
  data() {
    return {
      arrowUpIcon: arrowUpOutline,
      searchIcon: search,
      favoritesIcon: heartOutline,
      helpIcon: helpCircleOutline,
      isLocationPermissionRequired: false,
      isLocationBlocked: false,
      locationIsDenied: false,
      page: 1,
      pageElement: null,
      sort: 'score',
    }
  },
  watch: {
    $route(to, from) {
      if (from.name === to.name && Object.keys(to.query).length) {
        // TODO: check this
        //this.filtersValues = getFiltersInitialValues({ ...to.query, price: this.filtersValues.price });
        this.page = 1;
        this.fetchWines();
      }
    },
    location() {
      this.onSetLocation();
    },
    filtersValues(newValues, oldValues) {
      if (Object.keys(oldValues).length !== 0) {
        // If Shop Chain filter is changed we need to show reload shops list
        if (oldValues['shopChain'] !== newValues['shopChain']) {
          this.fetchShops(newValues['shopChain']);
          this.setShop();
        }
      }
    },
  },
  created() {
    if (isPlatform('mobileweb')) {
      const appStoreLink = getAppStoreLink();
      if (appStoreLink) {
        // TODO: Show modal with store link
        //window.location.href = appStoreLink;
      }
    }
    if (isPlatform('ios')) {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
  },
  async mounted() {
    this.pageElement = this.$refs.page.$el;

    await this.$store.dispatch('init', { locale: this.$i18n.locale, query: this.$route.query });

    // Stories are hidden for now
    //this.fetchStories();

    await this.initGeolocation();

    // This is disabled because when location is changed in location selector it triggers fetchWines
    // Maybe we don't need to call onSetLocation on page load, but only when user changed location

    //this.fetchFilters(this.$route.query);
    //this.fetchWines();

    logScreenViewEvent('home');

    // Scroll to top when back button is pressed
    useBackButton(10, (processNextHandler) => {
      this.scrollToTop();
      processNextHandler();
    });
  },
  computed: {
    ...mapState([
      'wines',
      'winesLoading',
      'winesLoadingError',
      'winesRegionNotAvailable',
      'totalWines',
      'stories',
      'storiesLoading',
      'config',
      'filtersValues',
      'filtersLoading',
    ]),
    ...mapState('location', [
      'location',
      'locationLoading',
      'locationError',
    ]),
    isNativeApp() {
      return isPlatform('capacitor');
    },
  },
  methods: {
    ...mapActions('location', ['checkGeolocation', 'initGeolocation']),
    ...mapActions('shops', ['fetchShops']),
    ...mapActions(['fetchFilters', 'fetchStories']),
    async doRefresh(event) {
      this.fetchStories();
      await this.checkGeolocation();
      await this.fetchFilters(this.$route.query);
      this.fetchWines();
      event.target.complete();
    },
    async onSetLocation() {
      this.fetchFilters(this.$route.query);
      this.onFiltersApply();
    },
    setShop(shop = 0) {
      this.$store.commit('setFiltersValues', { ...this.filtersValues, shop });
      this.onFiltersApply();
    },
    setSortOrder(sortOrder) {
      this.sort = sortOrder;
      this.page = 1;
      this.fetchWines();
    },
    onFiltersApply() {
      this.page = 1;
      Haptics.impact({ style: ImpactStyle.Light });
      this.fetchWines();
    },
    fetchMoreWines() {
      Haptics.impact({ style: ImpactStyle.Light });
      setTimeout(() => {
        this.page++;
        this.fetchWines();
      }, 200);
    },
    fetchWines() {
      this.$store.dispatch('fetchWines', {
        filters: this.filtersValues,
        page: this.page,
        sort: this.sort,
      });
    },
    async scrollToTop() {
      this.$refs.content.$el.scrollToTop(200);
    },
    openSearch() {
      this.$router.push({
        name: 'Search',
      });
    },
  }
}
</script>

<style lang="scss">
.home-toolbar {
  background-color: var(--ion-toolbar-background);
  height: 0;
}
.app-alert {
  background-color: var(--ion-color-danger-tint);
  color: var(--ion-color-danger-contrast);
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 20px;
}

.wine-list-error-container {
  margin-top: 50px;
}

.total-wines {
  padding: 10px 0;
  opacity: 0.4;
}

.rate-toast {
  font-weight: 600;
  --border-radius: 20px;

  .toast-header {
    font-weight: 600;
  }
  .toast-message {
    text-align: left;
  }
}

.more-filters-button {
  font-weight: 500;
}

.circle-button {
  --border-radius: 50%;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  width: 48px; /* Adjust the size as needed */
  height: 48px; /* Adjust the size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 800px) {
  .app-title {
    font-size: 3em;
    padding-bottom: 0;
  }
}
</style>

<style scoped>
.wine-card-col {
  --ion-grid-column-padding: 0;
}
.wine-list {
  height: 100%;
  min-height: 500px;
}
</style>
