<template>
  <!-- <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button @click="close">
          <ion-icon :icon="closeIcon"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header> -->
  <ion-content class="ion-padding">
    <div v-for="wine in wines" :key="wine.id">
      <wine-card :wine="wine" @click="close" />
    </div>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  modalController,
} from "@ionic/vue";
import { close } from "ionicons/icons";
import WineCard from "@/components/WineCard.vue";

export default {
  components: {
    IonContent,
    IonHeader,

    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    WineCard,
  },
  props: {
    wines: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      closeIcon: close,
    };
  },
  methods: {
    async close() {
      await modalController.dismiss();
    },
  },
};
</script>
